import type { ContactRelationAddDto, ContactRelationDto, ContactSubRoleDto } from 'src/backend';
import { getInitials } from 'src/utils/get-initials';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';

import { baseApi } from './baseApi';
import { AppUserDTO2Extended } from './userApi';


export interface ContactRelationDtoExtended extends ContactRelationDto {
    userRelatedTo: AppUserDTO2Extended;
    contactUser: AppUserDTO2Extended;
    initials: string;
    displayName: string;
}

export const contactApi = baseApi.enhanceEndpoints({ addTagTypes: ['ContactSubRoleDto', 'ContactRelationDto', 'BasicLoanDto', 'LoanDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getContactRelation: build.query<ContactRelationDtoExtended[], { loanRoleId: string, loanId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/contactRelation/${data.loanRoleId}`,
                method: 'GET',
            }),
            providesTags: [
                { type: 'ContactRelationDto', id: 'LIST' },
            ],
            transformResponse: (response: ContactRelationDto[]) => {
                return response.map((contact) => {
                    const userRelatedToDisplayName = getUserDisplayName(contact.userRelatedTo);
                    const contactUserDisplayName = getUserDisplayName(contact.contactUser);
                    const contactDisplayName = getUserDisplayName({
                        ...contact,
                        emailAddress: contact.contactUser.emailAddress,
                    });
                    return ({
                        ...contact,
                        userRelatedTo: {
                            ...contact.userRelatedTo,
                            initials: getInitials(userRelatedToDisplayName),
                            displayName: userRelatedToDisplayName,
                        },
                        contactUser: {
                            ...contact.contactUser,
                            initials: getInitials(contactUserDisplayName),
                            displayName: contactUserDisplayName,
                        },
                        initials: getInitials(contactDisplayName),
                        displayName: contactDisplayName,
                    })
                })
            },
        }),
        deleteContactRelation: build.mutation<void, { loanRoleId: string, loanId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/contactRelation/${data.loanRoleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', 'id': 'LIST' },
                { type: 'LoanDto', 'id': 'LIST' },
                { type: 'ContactRelationDto', id: 'LIST' }
            ],
        }),
        addContact: build.mutation<void, { loanId: string, loanRoleCurrentUserId: string, userId: string, iRelation: ContactRelationAddDto }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/contactRelation/${data.loanRoleCurrentUserId}`,
                method: 'POST',
                data: data.iRelation
            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', 'id': 'LIST' },
                { type: 'LoanDto', 'id': 'LIST' },
                { type: 'ContactRelationDto', id: 'LIST' }
            ],
        }),
        editContact: build.mutation<void, { loanId: string, contactLoanRoleId: string, iRelation: ContactRelationAddDto }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/contactRelation/${data.contactLoanRoleId}`,
                method: 'PUT',
                data: data.iRelation
            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', 'id': 'LIST' },
                { type: 'LoanDto', 'id': 'LIST' },
                { type: 'ContactRelationDto', id: 'LIST' }
            ],
        }),
        getContactSubRoles: build.query<ContactSubRoleDto[], { companyId: string }>({
            query: (data) => ({
                url: `/v1/lenders/${data.companyId}/subroles`,
                method: 'GET',
            }),
            providesTags: ['ContactSubRoleDto']
        }),
        createSubRoles: build.mutation<void, ContactSubRoleDto[]>({
            query: (data) => ({
                url: `/v1/lenders/subroles`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['ContactSubRoleDto']
        }),
    }),
    overrideExisting: true,
})

export const {
    useCreateSubRolesMutation,
    useGetContactRelationQuery,
    useDeleteContactRelationMutation,
    useAddContactMutation,
    useEditContactMutation,
    useGetContactSubRolesQuery
} = contactApi;



