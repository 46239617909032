
import type { BusinessRequestDto, EntityLabelDto, LoanDto, LoanEntityDto, RealEstateRequestDto } from 'src/backend';
import { SherpaEntityWithInformation } from 'src/slices/entity';

import { baseApi } from './baseApi';

export const entityApi = baseApi.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
    endpoints: (build) => ({
        addLabelsToEntity: build.mutation<LoanDto, EntityLabelDto>({
            query: (data) => ({
                url: "/v2/loanmanager/entityLabel",
                method: 'POST',
                data
            }),
        }),
        postUpSomeRealEstateInformation: build.mutation<LoanDto, { entityId: string, information: RealEstateRequestDto }>({
            query: (data) => ({
                url: `/v2/information/realEstate/${data.entityId}`,
                method: 'POST',
                data: data.information
            }),
        }),
        postUpSomeBusinessEntityInformation: build.mutation<LoanDto, { entityId: string, information: BusinessRequestDto }>({
            query: (data) => ({
                url: `/v2/information/business/${data.entityId}`,
                method: 'POST',
                data: data.information
            }),
        }),
        getLoanEntityById: build.query<SherpaEntityWithInformation, { loanId: string, loanEntityId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/loanEntity/${data.loanEntityId}`,
                method: 'GET',
            }),
            transformResponse: (loanEntity: LoanEntityDto) => ({
                ...loanEntity,
                ...loanEntity.mostRecentUserInformation,
                ...loanEntity.sherpaEntity,
                ...loanEntity.assetEntityCustomizations,
                id: loanEntity.id,
                label: loanEntity.label,
                customizations: loanEntity.assetEntityCustomizations,
                templateId: loanEntity.templateId,
                templateName: loanEntity.templateName,
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useAddLabelsToEntityMutation,
    usePostUpSomeRealEstateInformationMutation,
    usePostUpSomeBusinessEntityInformationMutation,
    useGetLoanEntityByIdQuery
} = entityApi;



